
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import MaterialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    Draggable,
    MaterialSelect
  }
})
export default class Attrs extends Vue {
  @Prop({ default: '' }) value!: '';

  title = '';
  list: any = [];
  attrItemName = '';
  galleryShow = false;
  attrIndex = 0;
  attrItemIndex = 0;
  imageIndex = 0;

  handleAddAttr () {
    if (!this.title) {
      this.$message.error('请先输入属性标题')
      return
    }
    this.list.push({ title: this.title, label: '', value: [] })
  }

  handleDelAttr (index: number) {
    this.list.splice(index, 1)
  }

  handleAddAttrIem (index: number) {
    if (!this.attrItemName) {
      this.$message.error('请先输入属性值')
      return
    }
    this.list[index].value.push({
      name: this.attrItemName,
      label: '',
      value: [{ image: '', zIndex: '' }]
    })
  }

  handleDelAttrIem (index: number, attrIndex: number) {
    this.list[index].value.splice(attrIndex, 1)
  }

  handleAddImg (index: number, attrIndex: number) {
    this.list[index].value[attrIndex].value.push({
      image: '',
      zIndex: ''
    })
  }

  chooseImage (attrIndex: number, attrItemIndex: number, imageIndex: number) {
    this.attrIndex = attrIndex
    this.attrItemIndex = attrItemIndex
    this.imageIndex = imageIndex
    this.galleryShow = true
  }

  handleDelImg (index: number, attrIndex: number, imageIndex: number) {
    this.list[index].value[attrIndex].value.splice(imageIndex, 1)
  }

  galleryBack (val: any) {
    this.list[this.attrIndex].value[this.attrItemIndex].value[
      this.imageIndex
    ].image = val[0].url
    this.galleryShow = false
  }

  handleSave () {
    this.$emit('save', JSON.stringify(this.list))
  }
}
