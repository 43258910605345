
import { Component, Prop, Vue } from 'vue-property-decorator'
import Editor from '@/components/editor.vue'
@Component({
  components: {
    Editor
  }
})
export default class AddDetails extends Vue {
  @Prop() value: any;
}
