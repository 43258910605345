
import { Component, Prop, Provide, Vue } from "vue-property-decorator";

@Component
export default class Dialog extends Vue {
  visible = {
    val: false,
  };
  @Prop() value: any;
  @Provide("visible")
  visibleVal = this.visible;
  // props
  @Prop({ default: "" }) title!: string; //弹窗标题
  @Prop({ default: "确认" }) confirmButtonText!: string; //确认按钮内容
  @Prop({ default: "取消" }) cancelButtonText!: string | boolean; //取消按钮内容
  @Prop({ default: false }) disabled!: boolean; //是否禁用
  @Prop({ default: false }) async!: boolean; //是否开启异步关闭
  @Prop({ default: true }) clickModalClose!: boolean; // 点击遮罩层关闭对话窗口
  // data
  others: any = [];
  typeList = [
    { label: "二维码", value: 1 },
    { label: "条形码", value: 2 },
    { label: "文本", value: 3 },
    { label: "裁剪", value: 4 },
  ];
  img_datas: any = [
    {
      name: "",
      angle: "",
      imgNW: "",
      imgNH: "",
    },
  ];
  get otherData() {
    return this.value;
  }

  addOthersData() {
    this.others.push({
      type: "",
      left: 0,
      top: 0,
      width: "",
      height: "",
      angle: 0,
      content: "",
      fontsize: 0,
      fill: "",
      background: "",
    });
  }

  delOthersData(index: number) {
    this.others.splice(index, 1);
  }
  // methods
  handleEvent(type: "cancel" | "confirm") {
    this.$emit(type);
    if (!this.async || type === "cancel") this.close();
  }

  onTrigger() {
    if (this.disabled) return;
    this.open();
  }

  close() {
    this.visible.val = false;
    this.$emit("close");
  }
  open() {
    this.visible.val = true;
    this.$emit("open");
  }
}
