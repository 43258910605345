
import { Component, Vue, Watch } from "vue-property-decorator";
import AddPrice from "@/components/model/add-price.vue";
import { PageMode } from "@/utils/type";
import MaterialSelect from "@/components/material-select/index.vue";
import ModelDetails from "@/components/model/details.vue";
import ModelAttrs from "@/components/model/attrs.vue";
import {
  apiModelList,
  apiModelDetail,
  apiModelCategoryList,
  apiModelAdd,
  apiModelEdit,
} from "@/api/model.ts";

@Component({
  components: {
    MaterialSelect,
    AddPrice,
    ModelAttrs,
    ModelDetails,
  },
})
export default class ModelEdit extends Vue {
  $refs!: { form: any };
  /** S Data **/
  mode: string = PageMode.ADD; // 当前页面: add-添加角色 edit-编辑角色
  pager: any;
  searchObj: any;
  // 表
  lists: Array<object> = [];
  activeName = "basic";
  categoryList: any = [];
  // 表单数据
  form: any = {
    zh_cn_title: "",
    en_title: "",
    image: "",
    product_number: "",
    zh_cn_design_area: "",
    en_design_area: "",
    zh_cn_design_description: "",
    en_design_description: "",
    zh_cn_technology: "",
    en_technology: "",
    cate_id: "",
    is_three: 0,
    status: 1,
    model_url: "",
    animate_model: "",
    sort: 1,
    scale: "16,16,16",
    rotation: "0,0,0",
    position: "0,0,0",
    factoryimg_type: 1,
    spec_value_list: [
      {
        id: "",
        image: "",
        cost_price: "",
        volume: "",
        weight: "",
        width: "",
        length: "",
        high: "",
        img_suffix: "jpeg",
      },
    ],
    fast_design: 1,
    connected: 1,
  };
  connected_model = [];
  modelList = [];

  // 表单校验
  rules: object = {
    zh_cn_title: [{ required: true, message: "请输入名称", trigger: "blur" }],
  };

  // 必传字段与对应的选项卡名字
  requireFields = [
    {
      fields: ["code", "name", "category_id", "goods_image"],
      activeName: "basic",
    },
    {
      fields: [
        "spec_value_list[0].stock",
        "spec_value_list[0].factory_price",
        "spec_value_list[0].sell_price",
        "spec_value_list[0].length",
        "spec_value_list[0].width",
        "spec_value_list[0].high",
      ],
      activeName: "price",
    },
    {
      fields: ["express_money", "express_template_id", "delivery_content"],
      activeName: "logistics",
    },
  ];

  // 规格绑定的数据
  specData: any = {
    spec_type: 1,
    spec_value: [],
    spec_value_list: [],
    specs_single: {},
  };

  storeList: any[] = [];

  /** E Data **/
  @Watch("specData", { deep: true })
  specDataChange(val: any) {
    this.form.spec_value_list = val.spec_value_list;
    this.form.spec_value = val.spec_value;
  }
  /** S Methods **/
  getCategory() {
    apiModelCategoryList({}).then((res) => {
      this.categoryList = res.lists;
    });
  }
  getDetail() {
    apiModelDetail({ id: this.form.id }).then((res) => {
      this.form = res;
      this.initData(res);
    });
  }
  // 初始化规格数据
  initData(data: any = {}) {
    const spec_value = [
      {
        has_image: false,
        id: "",
        name: "",
        spec_list: [
          {
            id: "",
            value: "",
            image: "",
          },
        ],
      },
    ];
    const spec_value_list = [
      {
        id: "",
        image: "",
        sell_price: "",
        lineation_price: "",
        cost_price: "",
        stock: "",
        volume: "",
        weight: "",
        bar_code: "",
      },
    ];

    const specData: any = {
      spec_value: data.spec_value || spec_value,
      spec_value_list: data.spec_value_list || spec_value_list,
      spec_type: data.spec_type || 1,
      specs_single: spec_value_list[0],
    };
    specData.spec_value.forEach((item: any) => {
      item.has_image = false;
      item.spec_list.forEach((sitem: any) => {
        sitem.image = "";
      });
    });

    if (data.spec_type == 1) {
      specData.spec_value = spec_value;
      specData.specs_single = data.spec_value_list[0];
    }
    Object.assign(this.specData, specData);
    // this.loading = false
  }
  handleSubmit() {
    if (this.mode === PageMode.EDIT) {
      apiModelEdit(this.form).then((res) => {});
    } else {
      apiModelAdd(this.form).then((res) => {});
    }
  }

  back() {
    this.$router.push({
      name: "model_list",
      params: {
        pager: this.pager as any,
        searchObj: this.searchObj as any,
      },
    });
  }
  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query;
    if (query.mode) this.mode = query.mode;
    this.pager = this.$route.params.pager as any;
    this.searchObj = this.$route.params.searchObj as any;
    this.getCategory();
    if (this.mode === PageMode.EDIT) {
      this.form.id = query.id * 1;
      this.getDetail();
    }
  }

  /** E Life Cycle **/
}
